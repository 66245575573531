import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
const MartialArts = () => {

  return (
    <div className="page-wrapper">
   
    
    <div className="banner">
      <div className="form-wrapper">
        <h3>Get Free Trial</h3>
        <form>
          <div className="form-group">
            <input type="text" className="form-control" placeholder="Name" />
          </div>
          <div className="form-group">
            <input type="text" className="form-control" placeholder="Kid’s Name" />
          </div>
          <div className="form-group">
            <input type="text" className="form-control" placeholder="Kid’s Age" />
          </div>
          <div className="form-group">
            <input type="text" className="form-control" placeholder="Mobile Number" />
          </div>
          <div className="form-group">
            <input type="text" className="form-control" placeholder="Email Address" />
          </div>
          <div className="text-center">
            <button type="submit" className="btn btn-primary">Book Now</button>
          </div>
        </form>
      </div>
      <div className="banner-text m2b">
        <h3>make them masters of <span>self</span><span> defence</span>
        </h3>
      </div>
      <div className="banner-img">
        <img src="/images/martial-arts/banner.png" alt="banner" />
      </div>
    </div>
    <section className="whatUGetMarital">
      <div className='border-box'>
         <div className='border-box1'>
          <div className='border border-1'></div>
          <div className='border border-2'></div>
          <div className='border border-3'></div>
          <div className='border border-4'></div>
         </div>
         <div className='border-box2'> </div>
      </div>
      <div className='whatUGetMarital-content'>
        <div className='whatUGetMarital-content-title'>
           <div className='left-sec'>
             <h1>train with the <span>best</span></h1>
           </div>
           <div className='right-sec'>
             <h3>WHAT YOU GET?</h3>
           </div>
        </div>
        <div className='container-fluid pdl-0'>
        <div className='row'>
          <div className='col-md-4 ft-14'>
           <p className='text-justify'>
           Many parents misunderstand martial arts with violence and hence think twice before enrolling their kids in martial arts. Martial Arts are systematized armed combat which is practiced for several reasons such as self-defence, competition, physical, mental development, and much more.</p>

           <p className='text-justify'>At Suprkids the effective training of martial arts aims to train the mind, body and spirit in sync. Training help kids to channelize their bursting energy in a productive way. Our professional trainers carve the lessons of respect, discipline and concentration that kids learn from our online marital classes. With such a reputed tradition, the practice itself can be used to groom and shape individual’s personality.</p>

           <p className='text-justify'>Martial arts help kids with mind and body balance. And has all the reasons that have nothing to do with fighting or violence.
           </p>
          </div>
          <div className='col-md-8 artImg'>
          <img className='artImage' src={process.env.PUBLIC_URL + '/images/martial-arts/art.png'} alt='slide' />
            </div>
        </div>
        </div>
      
      </div>
    </section>
    
    <section className=" programBenMartial">
       <div className='proBen-sec'>
          <div className='proBenTitle'>
            <h3>why kids should learn <br/>
the Martial arts?</h3>
          </div>

          <img src="/images/martial-arts/program-benefites.png" alt='' />
       </div>
       <div className='proBen-sec2'>
       <div className="program-box">
            <h3 className="mb15">PROGRAm benefits:</h3>
            <ul>
            <li>Defensive Skills</li>
            <li>Less Fear</li>
            <li>Physical Strength</li>
            <li>Flexible Body</li>
            <li>Mental Awareness</li>
            <li>Greater Self Defence</li>
            <li>Better Body Posture</li>
            <li>Self Discipline</li>
            </ul>
          </div>

          <div className="program-box2">
            <h3 className="mb15">What Will Kids Learn?</h3>
             <div className='row align-items-center j-center'>
               <div className='col-3 pd0'><span>Shotokan Karate Curriculum</span></div>
               <div className='col-3 pd0'><span>Weapon <br/>skills</span></div>
               <div className='col-3 pd0'><span>Physical fitness drills</span></div>
               <div className='col-3 pd0 br0'><span>Self-defense skills</span></div>
               <div className='col-3 pd0'><span>Safe board / brick breaking</span></div>
               <div className='col-3 pd0 br0'><span>Earn belts through testing</span></div>
             </div>
          </div>
       </div>
     
    </section>
    <section className="consultation-wrapper bg-lightr">
      <h3 className="mb40">How would Suprkid Martial Arts Program help your Kids:</h3>
      <div className="help-row bold-f">
        <div className="help-box j-center">
          <p>Great way to Learn <br/> Goal-Setting </p>
        </div>
        <div className="help-box j-center">
          <p>Help kids feel more confident </p>
        </div>
        <div className="help-box j-center">
          <p>Improves strength <br/>
and flexibility</p>
        </div>
        <div className="help-box j-center">
          <p>Improves <br/>
Self - Discipline</p>
        </div>
        <div className="help-box j-center">
          <p>Help kids build <br/>
Self- Esteem & focus</p>
        </div>
      </div>
    </section>
    
    <section className="portfolio-box">
      <h3 className="mb30">Glimpse of Martial Arts batch</h3>
      <div className="portfolio">
      <Carousel responsive={responsive}>
      <div>
              <img src={process.env.PUBLIC_URL + '/images/martial-arts/slide-1.png'} alt='slide' />
             
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + '/images/martial-arts/slide-2.png'} alt='slide' />
             
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + '/images/martial-arts/slide-3.png'} alt='slide' />
             
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + '/images/martial-arts/slide-1.png'} alt='slide' />
             
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + '/images/martial-arts/slide-2.png'} alt='slide' />
             
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + '/images/martial-arts/slide-3.png'} alt='slide' />
             
            </div>

</Carousel>
      
      </div>
       
    </section>
    <div className='kid_section'>
        <div className='container'>
          <div className='kid_image'>
            <img src={process.env.PUBLIC_URL + "/images/kid.png"} alt="kid" className='img-resp'/>
          </div>
        </div>

      </div>
  </div>
  )
}

export default MartialArts