import React from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <>      <footer>
    <div className='container'>
      <div className='footer-top'>
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <div className='footer-brand'>
              <img src={process.env.PUBLIC_URL + '/images/footerlogo.png'} alt="logo" />
              <div className='brand-content'>
                <p>We help you <br /> and your family <br /> stay fit, fun <br /> and happy
                </p>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-sm-12'>
            <div className='query-form'>
              <div className='form-title'>
                <p>Any Queries?</p>
              </div>
             
              <form>
              <textarea className='form-control' placeholder='Type Here'>

</textarea>
<button type='submit'>Submit</button>
              </form>
            </div>
          </div>
        </div>


      </div>
      <div className='footer-bottom'>
        <div className='row'>
          <div className='col-md-3 col-sm-6 col-xs-12 pd20'>
            <div className='footer-box'>
            <div className='footer-title'>
              <p>Contact</p>
            </div>
            <p>
              2nd Flood, Good wood
              building, Patanwala
              estate, LBS marg,
              Ghatkopar (west),
              Mumbai - 400086</p>
            <p className='mt-20'>support@maxxwellness.in </p>
            </div>
            
          </div>
          <div className='col-md-3 col-sm-6 col-xs-12 pd20'>
          <div className='footer-box'>
            <div className='footer-title'>
              <p>Know more on</p>
            </div>
            <ul className='menu-list'>
              <li><Link to="/">Super Kid</Link></li>
              <li><Link to="/">Make Me Fit</Link></li>
              <li><Link to="/">Super Mom</Link></li>
              <li><Link to="/">Maxx Blogs</Link></li>
            </ul>
            </div>
          </div>
          <div className='col-md-3 col-sm-6 col-xs-12 pd20'>
          <div className='footer-box'>
            <div className='footer-title'>
              <p>Latest News</p>
            </div>
            <p>
              <p className='txt-gr'>March 10, 2022</p>
              <p>How to lose fat in
                5 weeks at home</p>
              <p className='txt-gr mt-10'>March 05, 2022</p>
              <p>Managing Family and
                Nourishing Self</p>
            </p>
            </div>
          </div>
          <div className='col-md-3 col-sm-6 col-xs-12 pd20'>
          <div className='footer-box'>
            <div className='footer-title'>
              <p>Stay in touch</p>
            </div>
            <ul className='social-links'>
              <li><Link to="/"><i className="fa fa-instagram"></i></Link></li>
              <li><Link to="/"><i className="fa fa-facebook"></i></Link></li>
              <li><Link to="/"><i className="fa fa-pinterest-p"></i></Link></li>
            </ul>
          </div>
          </div>
        </div>
      </div>
    </div>
    <div className='footer-arrow'>
  <img src={process.env.PUBLIC_URL + '/images/bottom-icon.png'} alt="bottom-arrow" />
  </div>
  </footer>

  </>
  )
}

export default Footer