import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};
const Chess = () => {

    return (
        <div className="page-wrapper">


            <div className="banner">
                <div className="form-wrapper">
                    <h3>Get Free Trial</h3>
                    <form>
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Name" />
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Kid’s Name" />
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Kid’s Age" />
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Mobile Number" />
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Email Address" />
                        </div>
                        <div className="text-center">
                            <button type="submit" className="btn btn-primary">Book Now</button>
                        </div>
                    </form>
                </div>
                <div className="banner-text chess-bt">
                    <h3>Battle
                        of
                        Minds
                        on the
                        board.

                    </h3>
                </div>
                <div className="banner-img">
                    <img src="/images/chess/banner.png" alt="banner" />
                </div>
            </div>
            <section className='chess_program_sec'>
               <div className='row'>
               <div className="col-lg-3 col-md-3 ">
               </div>
               <div className="col-lg-6 col-md-6 ">
              <div className='chess-pro-content'>
              <h1>THE #1 CHess program</h1>
               <p>Chess is an excellent game to stimulate the brain and helps with several mental conditions. A board game for two players with strategic skills, Suprkid chess program is focused to teach children through mind building chess principles. Our online chess classes are under the guidance of professional trainers. Our trainer not only teaches chess but also empowers children to analyze their own game and learning’s so that they grow with every move they make.</p>
               <img src="/images/chess/about-program.png" alt="banner" />
              </div>
              
               </div>
               <div className="col-lg-3 col-md-3 ">
               <div className='program-what'>
               <div className='chess-pro-what-uget'>
                 <h3 class="text-left font2 mt10 ">WHAT YOU GET?</h3>
                     <ul>
                         <li>Chess Joining Kit</li>
                         <li>Live Coach / Mentor</li>
                         <li>Live Online Sessions</li>
                         <li>Certification</li>
                     </ul>
                 </div>

                 <div className='program-what-bottom'>
                     <h6>Personalised 
coaching is 
our DNA… 
</h6>
                     <h6 className='txtw'>Say NO to 
big group 
learnings!</h6>
                 </div>
               </div>

                 
               </div>
               </div>
            </section>
           
            <section className="const-section chess-sec">

                <div className="constation-row row">

                    <div className="col-lg-8 col-md-12 const-middle kid-big-title">
                        <div className="quick-box">
                            <h3>why kids should learn the chess?</h3>

                           
                                <div className="chess-program">
                                    <ul>
                                        <li className='chess-program-item'>
                                            <img src={process.env.PUBLIC_URL + '/images/chess/chess-icon-1.png'} alt="" />
                                            <h6>Critical Thinking</h6>
                                            <p>Chess develops analytical, 
synthetic and decision
making skills that can 
be applied to real life.

                                            </p>
                                        </li>
                                        <li className='chess-program-item'>
                                            <img src={process.env.PUBLIC_URL + '/images/chess/chess-icon-2.png'} alt="" />
                                            <h6>Confidence</h6>
                                            <p>Chess encourages children 
learn to focus, plan, and 
persevere through challenges, 
building self-confidence.

                                            </p>
                                        </li>
                                        <li className='chess-program-item'>
                                            <img src={process.env.PUBLIC_URL + '/images/chess/chess-icon-3.png'} alt="" />
                                            <h6>Competition</h6>
                                            <p>Chess enables the child to 
gain insights into the nature 
of competition which will help 
them in any competitive endeavor.

                                            </p>
                                        </li>
                                    </ul>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 const-last">
                        <div className="program-box">
                            <h3 className="mb15">Program Benefits:</h3>
                            <ul>

                                <li>Improves the brain’s cognitive functions </li>
                                <li>Raises a kid’s IQ level.</li>
                                <li>Increase kids’ problem-solving skills</li>
                                <li>Improves spatial skills & patience</li>
                                <li>Keeps both sides of the brain active </li>
                                <li>Improves the level of logical thinking.</li>
                                <li>Improves memory and concentration</li>
                                <li>Better planning and foresight</li>

                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 const-last">
                        <div className="side-image top33 left-34">
                            <img src="/images/design-elements.png" alt="" />
                        </div>
                        <div className="text-center chess-img">
                            <img src={process.env.PUBLIC_URL + '/images/chess/chess.png'} alt="" />
                        </div>


                    </div>
                </div>
            </section>
            <section className="consultation-wrapper">
                <h3 className="mb40">How would Suprkid chess Program help your Kids:</h3>
                <div className="help-row bold-f">
                    <div className="help-box">
                        <p>Playing chess not only
                            improves the brain’s
                            cognitive functions
                            but beyond. </p>
                    </div>
                    <div className="help-box">
                        <p>As chess keeps our
                            brains active, it raises
                            a kid’s IQ level </p>
                    </div>
                    <div className="help-box">
                        <p>Increase kids’
                            problem-solving skills
                            and patience. Improves
                            spatial skills</p>
                    </div>
                    <div className="help-box">
                        <p>Keeps both sides of
                            the brain active which
                            improves the level of
                            creative & logical
                            thinking.</p>
                    </div>
                    <div className="help-box">
                        <p>Improves memory
                            and concentration,
                            Better planning and
                            foresight</p>
                    </div>
                </div>
            </section>

            <section className="portfolio-box">
                <h3 className="mb30">Glimpse of Chess batch</h3>
                <div className="portfolio">
                    <Carousel responsive={responsive}>
                        <div>
                            <img src={process.env.PUBLIC_URL + '/images/chess/chess-slide-1.png'} alt='slide' />

                        </div>
                        <div>
                            <img src={process.env.PUBLIC_URL + '/images/chess/chess-slide-2.png'} alt='slide' />

                        </div>
                        <div>
                            <img src={process.env.PUBLIC_URL + '/images/chess/chess-slide-3.png'} alt='slide' />

                        </div>
                        <div>
                            <img src={process.env.PUBLIC_URL + '/images/chess/chess-slide-1.png'} alt='slide' />

                        </div>
                        <div>
                            <img src={process.env.PUBLIC_URL + '/images/chess/chess-slide-2.png'} alt='slide' />

                        </div>
                        <div>
                            <img src={process.env.PUBLIC_URL + '/images/chess/chess-slide-3.png'} alt='slide' />

                        </div>

                    </Carousel>

                </div>

            </section>
            <div className='kid_section'>
                <div className='container'>
                    <div className='kid_image'>
                        <img src={process.env.PUBLIC_URL + "/images/kid.png"} alt="kid" className='img-resp' />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Chess