import { Fragment } from 'react'
import Header from './components/Header'
 import Footer from './components/Footer'

import Home from './pages/Home';
import RubikCube from './pages/RubikCube'
import Chess from './pages/Chess'
import MartialArts from './pages/MartialArts';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Fragment>
              <Router>
              <Header/>
                <Switch>
                   <Route exact path="/" component={Home} />
                   <Route exact path="/rubik-cube" component={RubikCube} />
                   <Route exact path="/chess" component={Chess} />
                   <Route exact path="/martial-arts" component={MartialArts} />
                   
                </Switch>
                <Footer/>
              </Router>
    </Fragment>
  );
}

export default App;
