import React,{useState} from 'react'
import { Link, NavLink, useLocation  } from 'react-router-dom';
const Header = () => {
  let location = useLocation();
    const [isOpen, setOpen] = useState(false);
    const toogleMenu = () =>{
      setOpen(!isOpen);
    }
  return (
    <header>
        <div className="side-image">
      <img src="/images/design-elements.png" alt="" />
    </div>
    <div className="header-top">
      <div className="top-section">
        <div className="top-box-left">
          <ul>
            <li><Link to="#">Home <span>&gt;</span></Link></li>
            <li><Link to="#">Suprkid <span>&gt;</span></Link></li>
            <li><Link to="#">Activities</Link></li>
          </ul>
        </div>
        <div className="top-box-right">
          <Link to="#">Contact Us</Link>
        </div>
      </div>
    </div>
    <div className="header-bottom">
      <div className="container">
        <div className="brand">
          <Link to="/">
            <img src="/images/logo.png" alt="logo" />
          </Link>
          <button className={isOpen ? 'menubutton opened' : 'menubutton'} onClick={toogleMenu}><span /><span /><span /></button>
        </div>
        <div className="menu">
          <ul className={isOpen ? 'opened' : ''}>
            <li><Link to="/" className={location.pathname === '/' && "active"}>ABACUS</Link></li>
            <li><Link to="/chess" className={location.pathname === '/chess' && "active"}>CHESS</Link></li>
            <li><Link to="/martial-arts" className={location.pathname === '/martial-arts' && "active"}>martial arts</Link></li>
            <li><Link to="/rubik-cube" className={location.pathname === '/rubik-cube' && "active"}>rubik cube</Link></li>
            <li><Link to="#">Dance</Link></li>
            <li><Link to="#">vedic math</Link></li>
            <li><Link to="#">SoduKu</Link></li>
          </ul>
        </div>
      </div>
    </div>
  </header>
  )
}

export default Header